.spp__reviews_head {
  .spp-reviews {
    display: block;
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: '有幫助 (';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: '沒有幫助 (';
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}
