.page-navigation {
  margin-top: -30px;
  margin-left: 80px;
  .menu-reference {
    &--loyalty,
    &--signin,
    &--account {
      display: none;
    }
    .menu {
      li {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

.margin_top_bottom {
  @include swap_direction(margin, 0.8em 0);
}

.sign-in-component {
  &.registration-confirmation {
    .sign-in-component {
      &__sms-opt-in {
        display: none;
      }
      &__birthday-program {
        float: none;
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  .sign-in-component__form {
    #sign-in-component__FIRST_NAME {
      display: none;
    }
  }
}

.pg_wrapper {
  .messages {
    color: $color-red;
  }
  &.viewcart-empty {
    .single-message {
      color: #9eafff;
    }
  }
  .profile-info__item {
    .mobile_code {
      display: inline-block;
      float: left;
      margin-right: 20px;
    }
    .mobile_number {
      display: inline-block;
      float: left;
    }
  }
}

.main-content {
  .page-not-found {
    width: auto;
    padding: 100px 0;
  }
}

.sidebar-page {
  &__sidebar,
  &__content {
    padding-top: 175px;
  }
}

.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -14px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-branding__logo {
  float: right;
}

.brand-renutriv,
#beauty_feed,
.is-alternate {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3-white.png');
      }
    }
  }
}

.product-full__personal {
  .social-share-icons {
    display: inline-block;
    .social-share__link {
      width: 24px;
      height: 24px;
      &.twitter,
      &.pinterest {
        display: none;
      }
      &.weibo {
        background-image: url('/media/images/global/social_icons/weibo_black_24x24.png');
      }
      &.wechat {
        background-image: url('/media/images/global/social_icons/wechat_black_24x24.png');
      }
    }
  }
}

.qrcodeTable {
  position: absolute;
  padding: 15px;
  bottom: 35px;
  left: 75px;
  z-index: 999;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 3px #808080;
  h3 {
    font-size: 16px;
    letter-spacing: 2px;
    margin: 0 0 0 0;
    padding: 15px 0 0 0;
    line-height: 10px;
    font-weight: bold;
  }
}

.brand-renutriv {
  .social-share-icons {
    .social-share__link {
      &.weibo {
        background-image: url('/media/images/global/social_icons/weibo_white_24x24.png');
      }
      &.wechat {
        background-image: url('/media/images/global/social_icons/wechat_white_24x24.png');
      }
    }
  }
  .qrcodeTable h3 {
    font-size: 16px;
    letter-spacing: 2px;
    color: #000;
    font-weight: bold;
    text-transform: none;
    margin: 0;
    padding: 0;
  }
  .mpp {
    .mpp__header {
      padding-top: 50px;
    }
    .mpp__product-sort-container {
      top: 60px;
    }
  }
}

.section-store-locator.device-pc {
  .store-locator {
    margin-top: 15px;
    .local-search-form {
      .local-search-form__city {
        width: 100% !important;
      }
    }
  }
}

.page-utilities {
  &__account {
    margin-top: 0;
  }
  &__item {
    .user-login-state {
      height: 1px;
      bottom: 48px;
    }
  }
}

.ANR {
  .hero-block__headline {
    .header__headline {
      line-height: 60px;
    }
  }
}

[lang='en-e-HK'] {
  .product_brief {
    &__sub-line {
      display: none;
    }
  }
}

/********** Regional Specific Styles **********/

[lang='zh-e-HK'] {
  .section-home {
    .anr_eye,
    .double_wear,
    .estee_stories {
      .hero-block__headline {
        .header__headline {
          font-size: 72px;
          letter-spacing: 0;
          line-height: 86px;
        }
      }
      .hero-block__promo {
        margin-top: 30px;
        .text-promo {
          font-size: 16px;
          line-height: 24px;
        }
        &-copy {
          .hero-block__cta {
            margin-top: 30px;
          }
        }
      }
    }
    .anr_eye {
      .hero-block__headline {
        left: 52%;
        top: 225px;
      }
      .hero-block__promo {
        left: 52%;
        top: 35%;
        padding: 0;
      }
      @media #{$medium-only} {
        .hero-block__headline {
          left: 52%;
          top: 19%;
        }
        .hero-block__promo {
          top: 53%;
        }
      }
      @media #{$large-only} {
        .hero-block__headline {
          left: 52%;
        }
        .hero-block__promo {
          top: 59%;
        }
      }
    }
    .pure_color_envy {
      .hero-block__headline {
        left: 60%;
        top: 12%;
      }
      .hero-block__promo {
        left: 60%;
        top: 51%;
        .text-promo {
          font-size: 16px;
          line-height: 24px;
        }
      }
      @media #{$medium-only} {
        .hero-block__headline {
          top: 18%;
          h1.header__headline {
            line-height: 60px;
          }
        }
        .hero-block__promo {
          top: 57%;
        }
      }
      @media #{$large-only} {
        .hero-block__headline {
          top: 15%;
        }
        .hero-block__promo {
          top: 54%;
        }
      }
    }
    .double_wear {
      .hero-block__headline {
        left: 55%;
        top: 225px;
      }
      .hero-block__promo {
        left: 54%;
        top: 45%;
      }
      @media #{$medium-only} {
        .hero-block__headline {
          left: 50%;
          top: 22%;
        }
        .hero-block__promo {
          left: 72%;
          top: 70%;
        }
      }
      @media #{$large-only} {
        .hero-block__headline {
          left: 50%;
        }
        .hero-block__promo {
          left: 49%;
          top: 60%;
        }
      }
    }
    .estee_stories {
      .hero-block__promo {
        left: 10%;
        top: auto;
        right: auto;
        bottom: 10%;
      }
      @media #{$xlarge-up} {
        .hero-block__promo {
          bottom: 32%;
        }
      }
    }
    .cta {
      font-size: 14px;
    }
  }
  .section-esteestories {
    .ee-landing__text {
      .text--short {
        font-size: 15px;
        line-height: 24px;
        .text--bold {
          font-weight: normal;
          font-size: 15px;
        }
      }
      h4 {
        letter-spacing: 0.2em;
        line-height: 36px;
      }
      .ee-subhead {
        letter-spacing: 0.15em;
        line-height: 24px;
      }
      a.link--bold {
        font-size: 15px;
      }
    }
    .ee-landing__mtext {
      padding-top: 20px;
      h4 {
        letter-spacing: 0.1em;
        line-height: 1.2em;
      }
    }
  }
  .formatter-estee-edit {
    .estee-edit__header-tags {
      font-size: 14px;
      .estee-edit__header-tags-label {
        font-weight: normal;
        font-size: 14px;
      }
    }
    .header-meta__headline {
      @media #{$large-up} {
        font-size: 72px;
        line-height: 86px;
        letter-spacing: 0.1em;
      }
    }
    .header-meta__subhead {
      @media #{$large-up} {
        font-size: 24px;
        line-height: 1.5em;
        letter-spacing: 0.05em;
      }
    }
    .estee-edit__header {
      .header-meta {
        h3 {
          font-size: 40px;
          letter-spacing: 0.1em;
        }
        p {
          font-size: 15px;
          letter-spacing: 0.15em;
          line-height: 24px;
          strong a {
            font-size: 15px;
            letter-spacing: 0.15em;
          }
        }
      }
    }
    .ee-disco-more {
      .ee-disco-more__item {
        .text--short {
          font-size: 15px;
          letter-spacing: 0.15em;
          .ee-disco-more__label.text--bold {
            font-size: 15px;
            letter-spacing: 0.15em;
            font-weight: normal;
          }
        }
      }
    }
  }
  .product_brief {
    &__sub-header {
      display: none;
    }
    &__sub-line {
      font-size: 30px;
      letter-spacing: 0.15em;
      line-height: 41px;
    }
  }
  .spp {
    .product_brief {
      &__sub-line {
        font-size: 30px;
      }
    }
  }
  .product-full {
    &__attribute {
      h5,
      p {
        font-size: 16px;
        letter-spacing: 0.15em;
        line-height: 24px;
      }
    }
    h3 {
      &.product-full__title {
        letter-spacing: -0.05em;
      }
      &.product_brief__sub-line {
        letter-spacing: -0.005em;
      }
    }
  }
  .spp-product__details {
    &-column02 {
      .spp-product__details {
        &-attribute {
          &__label,
          p {
            font-size: 14px;
          }
        }
      }
    }
    ul li {
      font-size: 14px;
      letter-spacing: 0.15em;
      line-height: 24px;
    }
  }
  .spp-product {
    &__how-to-use {
      .headline--secondary {
        font-size: 48px;
        letter-spacing: 0.05em;
        line-height: 52px;
      }
      .text-product-listing-names-sub,
      .text-promo {
        font-size: 16px;
        letter-spacing: 0.15em;
        line-height: 24px;
      }
      .spp-howtouse__product-image {
        padding-bottom: 20px;
      }
    }
  }
  .device-pc {
    .discover-more {
      &__header {
        font-size: 48px;
      }
    }
    .discover_more {
      &__product-price {
        font-size: 12px;
      }
    }
    #rn_container {
      .renutriv_section_wrapper {
        h3 {
          letter-spacing: 0.02em;
        }
        p {
          letter-spacing: 0.15em;
        }
        #renutriv_section-1 {
          h3 {
            font-size: 40px;
          }
          p {
            font-size: 16px;
          }
        }
        @media #{$large-up} {
          #renutriv_section-3 {
            h3 {
              font-size: 72px !important;
            }
            p {
              letter-spacing: 0.02em !important;
            }
          }
        }
        #renutriv_section-4 {
          h3 {
            font-size: 35px;
          }
          p {
            line-height: 24px;
          }
        }
        #renutriv_section-6 {
          .category_links {
            li {
              a {
                font-size: 30px;
                line-height: 24px;
                letter-spacing: 0.15em;
              }
            }
          }
        }
      }
    }
    #re-nutriv-alchemy_container {
      .re-nutriv-alchemy_section {
        .re-nutriv-alchemy_text {
          h2,
          h3 {
            font-size: 35px;
            letter-spacing: 0.02em;
          }
          p {
            font-size: 16px;
            letter-spacing: 0.15em;
            line-height: 24px;
          }
        }
        &-container {
          .re-nutriv-alchemy_environments {
            h2 {
              font-size: 35px;
              letter-spacing: 0.02em;
            }
          }
        }
      }
      #re-nutriv-alchemy_section-1 {
        .re-nutriv-alchemy_text {
          .subhead {
            font-size: 20px;
            letter-spacing: 0.02em;
          }
        }
      }
      #re-nutriv-alchemy_section-3 {
        .re-nutriv-alchemy_text {
          h3 {
            font-size: 20px;
            letter-spacing: 0.02em;
          }
        }
      }
      #re-nutriv-alchemy_section-7 {
        .re-nutriv-alchemy_text {
          p {
            font-size: 20px;
            letter-spacing: 0.02em;
          }
        }
      }
      .product_brief {
        &__sub-line {
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
  .brand-renutriv {
    .product-full {
      h3.product_brief {
        &__sub-line {
          font-size: 30px;
          letter-spacing: normal;
        }
      }
      &__attribute {
        margin-top: 15px;
        h5,
        p {
          letter-spacing: 0.15em;
        }
      }
    }
    h3.product_brief__header {
      font-size: 22px;
      &.product_brief__sub-line {
        font-size: 16px;
      }
    }
    .spp-product__details {
      .spp-product__details-header {
        font-size: 30px;
        letter-spacing: 0.02em;
        line-height: 48px;
      }
      .spp-product__details-attribute {
        h5,
        p,
        ul li {
          letter-spacing: 0.15em;
        }
      }
      .spp-product__details-description {
        letter-spacing: 0.2em;
      }
    }
    h5.discover_more {
      &__product-sub-header {
        letter-spacing: 0.15em;
        padding-top: 10px;
      }
    }
    .mpp {
      .mpp__header {
        font-size: 40px;
        letter-spacing: 0.02em;
        line-height: 48px;
        padding-bottom: 10px;
      }
    }
  }
  #colorbox {
    &.colorbox__quickshop {
      .quickshop__tabs-control {
        li {
          font-size: 16px;
        }
      }
      .quickshop__price-size {
        .quickshop__price-text,
        .quickshop__size-text {
          font-size: 14px;
          letter-spacing: 0.05em;
          line-height: 24px;
        }
      }
      .quickshop__tab {
        letter-spacing: 0.15em;
        line-height: 24px;
      }
    }
  }
  .mpp {
    .product_brief {
      &__sub-line {
        font-size: 16px;
        letter-spacing: 0.15em;
        line-height: 24px;
      }
      &__desc1,
      &__misc-flag {
        font-size: 14px;
        letter-spacing: 0.15em;
        line-height: 18px;
      }
    }
  }
  .ff-quiz__inner {
    .ff-quiz {
      &__intensity-image,
      &__coverage-image {
        font-size: 72px;
      }
      &__headline {
        font-size: 40px;
        &--tertiary {
          font-size: 14px;
        }
      }
      &__nav-label {
        font-size: 14px;
      }
    }
  }
  /***** boutiques styles *****/
  .el_boutique_carousel_slide {
    .el_boutique_h1 {
      font-size: 48px;
      letter-spacing: 0.2px;
    }
    #r4_mod3_slide3 {
      .el_boutique_h2 {
        font-size: 15px;
      }
    }
    .el_boutique_body {
      p {
        font-size: 15px;
        line-height: 24px;
      }
    }
    .r4_mod3_slide2_pos,
    .r4_mod3_slide3_pos {
      padding-right: 5%;
    }
  }
  #r4_anr_info_copy2,
  #r4_anr_info_copy3 {
    position: relative;
    margin-right: 5%;
  }
  .el_boutique_h2 {
    .el_boutique_body {
      white-space: normal;
    }
  }
  /***** end boutiques *****/
  .sidebar-page {
    &__sidebar {
      .menu a {
        font-size: 14px;
      }
    }
    &__content {
      &-title {
        font-size: 40px;
        padding-top: 10px;
      }
    }
  }
  .customer-service {
    &--shopping {
      .sidebar-page {
        &__content {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
    &-contact-us-page {
      .address-form {
        &__registered-container {
          padding-left: 0;
        }
        &__address-fields {
          p {
            padding-left: 0;
          }
        }
      }
    }
  }
  .corporate-information-page {
    h2 {
      font-size: 40px;
    }
  }
  .search-page {
    .search-help {
      .search-page__headline {
        font-size: 30px;
      }
    }
  }
  .el-search-block {
    &__links {
      a {
        font-size: 30px;
      }
    }
  }
  .section-store-locator.device-pc {
    .store-locator {
      .store-locator__title {
        font-size: 40px;
      }
      .store-locator-location-info {
        .location-info__link-item {
          text-decoration: none;
          .location-info__directions-link {
            border-bottom: 1px solid #040a2b;
          }
        }
      }
      .door-row {
        .door-row__name {
          font-size: 18px;
        }
        .door-row__closest {
          font-size: 14px;
        }
      }
    }
  }
  .page-footer {
    .page-sticky-footer {
      .page-sticky-footer {
        &__inner {
          font-size: 16px;
        }
      }
    }
    .responsive-footer {
      .menu {
        li a {
          letter-spacing: 0.15em;
        }
      }
    }
    .special-offers-tray {
      letter-spacing: 0.15em;
      .offers-register-form,
      .offers-sign-in-form,
      .offers-email-signup-form {
        .headline--offers-tray {
          font-size: 20px;
        }
      }
      .special-offer {
        .special-offer__text {
          span {
            font-size: 20px;
            &.offer_content1,
            &.offer_content2 {
              font-size: 18px;
            }
          }
        }
      }
      .special-offer-benefits {
        .benefits {
          font-size: 18px;
        }
      }
    }
  }
  body {
    .formatter-estee-edit__content {
      .basic-textarea-v1 {
        p {
          font-size: 15px;
          letter-spacing: 0.15em;
          line-height: 24px;
        }
        h4.ee--subhead {
          font-size: 24px;
          letter-spacing: 0.1em;
          line-height: 1.5em;
        }
      }
    }
    .brightening-page,
    .sensitive-skin-page,
    .anti-blemish-page,
    .multiple-signs-of-aging-page,
    .radiant-health-page,
    .lifting-firming-page,
    .anti-wrinkle-page {
      .hero-block__headline {
        .header__headline {
          font-size: 72px;
        }
      }
      .hero-block__promo-copy {
        .text-promo__subcopy {
          font-size: 16px;
          letter-spacing: 0.15em;
        }
      }
    }
  }
  .page-sticky-footer {
    .drawer-formatter__content {
      .special-offer {
        .special-offer {
          letter-spacing: 0.15em;
          &__header {
            font-size: 20px;
            font-weight: normal;
          }
          &__text1,
          &__text2 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .special-offer-benefits {
    letter-spacing: 0.15em;
    .benefits_header {
      font-size: 20px;
      span {
        font-size: 18px;
      }
    }
  }
}

/********** End the Regional **********/

.bisPcIframe {
  height: 240px;
  width: 410px;
}

#waitlist {
  input[type='email'] {
    padding: 5px 10px;
    width: 410px;
  }
  input[type='submit'] {
    float: right;
    margin-top: 15px;
    padding: 7px 14px;
    border: 0;
  }
}

.elc-user-state {
  &-logged-in,
  &-anonymous {
    .waitlist_thankyou_message {
      margin: 25px 0 0 0;
    }
    #colorbox {
      #cboxClose {
        #{$rdirection}: 5px;
        top: 5px;
      }
    }
  }
}

.product-full {
  &__price-size {
    .product {
      &__inventory-status {
        height: auto;
        margin: 10px 0 0 0;
        clear: both;
      }
    }
  }
}

.product_brief__buttons--shaded {
  .tooltip {
    display: none;
  }
}

.contact-us-page {
  .address-form {
    .address-form__address-fields {
      .address-form__phone-1-container {
        &.form-item {
          &.left {
            clear: none;
            padding-left: 0px;
          }
        }
      }
      .required-mark {
        display: none;
      }
      .address-form__registered-container,
      .text--form-help {
        padding-left: 0;
      }
    }
  }
  .contact-form-confirm {
    h3 {
      font-size: 30px;
      font-weight: bold;
    }
  }
}

.spp-product__reviews {
  display: none;
}

.spp__reviews_head {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-rd-main-header {
        .pr-rd-review-total {
          clear: both;
        }
      }
    }
  }
}

.mpp {
  .product_brief {
    &__sku-price {
      margin-top: 25px;
    }
  }
}

.ee-social-share {
  li {
    .twitter,
    .pinterest {
      display: none;
    }
    &:nth-child(n + 2) {
      display: none;
    }
  }
  &__tooltip-content {
    width: 100%;
  }
}

.page-sticky-footer {
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      border: none;
    }
  }
  &__right__inner {
    float: right;
    &.page-sticky-footer__right__offers {
      border-right: 1px solid #a4a4ab;
      height: 23px;
      line-height: 23px;
      padding-right: 14px;
      margin: 10px 14px 0 0;
      float: left;
    }
  }
  .drawer-formatter {
    &__content {
      .special-offer {
        margin: 10px 0;
        padding: 10px;
        .button {
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }
}

.email_signup_sucess_popup {
  #cboxWrapper {
    div:nth-child(2) {
      height: 200px;
    }
  }
}

#address_book {
  .address_form_container {
    .form-item {
      .mobile_code {
        width: auto;
        display: inline-block;
        float: left;
        margin-right: 20px;
      }
      .mobile_number_label {
        display: block;
      }
      .mobile_number {
        display: inline-block;
        width: 197px;
      }
    }
  }
}

#index {
  #shipping-panel {
    .address-form {
      .form-item {
        .area-code {
          display: inline-block;
          float: left;
          @include swap_direction(margin, 0 20px 20px 0);
          label {
            display: block;
          }
        }
        &.phone-1 {
          width: 100%;
        }
        .mobile-number {
          display: inline-block;
        }
      }
    }
  }
}

.account-wishlist {
  .product__inventory-status {
    .product__inv-status-item {
      text-align: center;
      width: 100%;
    }
  }
}

.email_wish_popover {
  .fieldset {
    .headline--section {
      margin-bottom: 0;
    }
    #email_wishlist {
      padding-top: 22px;
      .error_messages {
        top: 0;
        position: static;
        left: 0;
        .empty_email {
          margin-left: 0;
        }
      }
    }
  }
}

.section-products {
  .mpp {
    #mpp__filter-tab {
      display: none;
    }
  }
}

.email_popover {
  .welcome-title {
    span {
      font-size: 2em !important;
    }
  }
  .email_popover__content {
    &.thanks {
      h3 {
        font-size: 46px;
        margin-bottom: 10px;
      }
    }
  }
}

@media #{$medium-only} {
  .f-gwppromo-3 {
    .hero-block__headline--left,
    .hero-block__promo--left {
      #{$ldirection}: 5% !important;
    }
    .hp-gwp-under-cta {
      width: 100%;
    }
  }
}

.welcome15-overlay {
  .email_popover {
    &__social_icons {
      bottom: auto;
    }
    .email_input {
      margin-#{$ldirection}: 0;
    }
  }
  .offer_popup_err_msg {
    color: $color-red;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
  }
}

.section-esteestories {
  .ee-slick-carousel__logo,
  .ee-tout__logo {
    margin-top: 0;
  }
}

.ff-results {
  .ff-results__regimen-steps {
    display: none;
  }
  .ff-results__matches {
    clear: both;
  }
}

input[type='text'] {
  color: $color-black;
}

.wishlist-page {
  .wishlist_prod_title_desc {
    height: 140px;
  }
}

#colorbox {
  &.colorbox__quickshop {
    .quickshop__reviews {
      display: none;
    }
  }
}

.phones {
  .area-code {
    select.field {
      padding-right: 50px;
    }
  }
}

.profile-info {
  .mobile_code {
    select.field {
      padding-right: 50px;
    }
  }
}

#wp_samples {
  .pg_wrapper {
    &.extended-mpp {
      max-width: 70%;
    }
  }
}

.samples {
  .price {
    &.checkout_gwp {
      display: none;
    }
  }
}

/***** Boutique page style - Skincare Supreme *****/
#supreme-power_container {
  .mpp-carousel {
    .carousel li {
      a {
        h3 {
          margin-bottom: 0;
        }
      }
      .el-product_stars-rating {
        display: none;
      }
      .el-product_alt_description-text {
        margin-bottom: 7px;
        font-size: 12px;
        letter-spacing: 0.75px;
        line-height: normal;
        text-transform: uppercase;
      }
      .el-product_rgn-subheading {
        text-transform: capitalize;
        font-size: 16px;
        line-height: normal;
      }
    }
    .hidden-products-markup-to-clone,
    .el-product_id-list,
    .el-product_shop-all-container {
      display: none;
    }
  }
  #supreme-power_section-1 {
    .product-container {
      left: 73%;
      .hotspot {
        position: absolute;
        text-align: center;
        img {
          display: none;
          margin: 40% auto 0;
        }
        &:hover img {
          display: block;
        }
      }
      .eye-balm {
        top: 0%;
        left: 0;
        width: 51.5%;
        height: 33%;
        img {
          margin-right: 7%;
          width: 60.5%;
          height: 23%;
        }
      }
      .creme {
        top: 33%;
        left: 7%;
        width: 51%;
        height: 39%;
        img {
          width: 58.75%;
          height: 19.5%;
        }
      }
      .left,
      .middle,
      .right,
      .glow {
        position: inherit;
      }
    }
  }
  .video_container {
    .close_btn {
      padding: 0px;
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      input[type='radio'] ~ label {
        &::before,
        &::after {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}

.email-promotion {
  .email-promotion {
    &__image-container,
    &__video-container {
      position: sticky;
    }
  }
}
