body {
  .page-sticky-footer {
    .drawer-formatter__content {
      .special-offer {
        .special-offer {
          &__img {
            margin-top: 12px;
          }
        }
      }
    }
  }
  &.section-re-nutriv * {
    font-family: $roboto-condensed;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

.book-appt-container {
  input {
    color: $color-black;
  }
}

.appt-book {
  margin-top: 20px;
  .appointment-select {
    .appointments-container {
      .confirm-container {
        .start-over {
          clear: left;
        }
      }
    }
    .book-appt-container {
      .appt_hk_phone_code {
        height: 40px;
        margin: 0 0 0 46px;
        box-shadow: inset 0 0 5px $color-light-gray;
        border-color: $color-light-gray;
      }
      .appt-book-mobile {
        &.appt-book-hk-mobile {
          margin: 0px;
          width: 42%;
        }
      }
    }
  }
}

[lang='zh-e-HK'] {
  .product-brief {
    &__sub-header {
      display: none;
    }
  }
  .appt-book {
    .location-select-section-content {
      &__title {
        font-size: 72px;
        line-height: 86px;
        letter-spacing: 0;
      }
    }
    &-location-selector-copy {
      font-size: 16px;
    }
    &-location-selector {
      .location-select {
        &.selectbox {
          .selectBox-label {
            font-size: 16px;
          }
        }
      }
    }
    &-page-header-content {
      h2 {
        font-size: 50px;
        padding-top: 40px;
      }
    }
    .service-select {
      .section-header {
        &__inner {
          font-size: 16px;
        }
      }
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP.jpg');
}

@media #{$large-up} {
  .appt-book-overlay {
    &.appt-book-datepicker-overlay {
      .overlay-content-container {
        padding: 60px 20px;
      }
    }
  }
}

.section-re-nutriv {
  .page-navigation__menu {
    .basic-menuref-v1 {
      .menu-ref__title {
        a {
          color: $color-navy;
        }
      }
    }
  }
  #renutriv {
    #renutriv {
      &_nav {
        top: 120px;
      }
    }
  }
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url(/media/images/global/lo3-white.png);
      }
    }
  }
}

.wp-overlay-content {
  width: 90%;
}

#viewcart {
  #colorbox {
    button {
      &#cboxClose {
        right: 23px;
      }
    }
  }
}

.registration__email-list-text {
  &.label {
    &.error {
      a {
        color: $color-red;
      }
    }
  }
}

[lang='zh-e-HK'] {
  .device-pc {
    .appt-book {
      .service-select {
        .service {
          .service-details {
            .service-title {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

// 2018 Creative refresh
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 26px;
  }
  @media #{$xlarge-up} {
    left: 50px;
  }
}

.utility-nav__account {
  @media #{$medium-up} {
    left: -20px;
  }
  @media #{$xlarge-up} {
    left: -10px;
  }
}

.page-header.alt-color-white {
  .page-branding {
    .trust_mark_header__link {
      background-image: url('/media/images/global/lo3.png');
      top: 0px;
    }
    &__logo {
      margin-top: 20px;
    }
  }
  .header-items {
    padding-bottom: 40px;
  }
  & ~ .page-main {
    .home-formatter {
      @media #{$medium-up} {
        margin-top: -110px;
      }
    }
  }
}

[lang='en-e-HK'] {
  .product-brief {
    &__sub-line {
      display: none;
    }
  }
}

.tabbed-products-block {
  .grid--mpp__carousel {
    .grid--mpp__items {
      .grid--mpp__item {
        .product-brief {
          &__sub-line {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.product_brief {
  &__sub-line {
    font-size: 16px;
  }
}

[lang='zh-HK'] {
  .device-pc {
    .product_brief,
    .product-brief {
      &__sub-header {
        display: none;
      }
    }
  }
}

[lang='en-HK'] {
  .device-pc {
    .product_brief,
    .product-brief {
      &__sub-line {
        display: none;
      }
    }
  }
}

.is-min-nav {
  .header-items {
    min-height: 65px;
  }
}

.adpl {
  select,
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    & + label {
      pointer-events: none;
    }
  }
}

.terms_conditions {
  h3 {
    font-size: 36px;
    letter-spacing: -0.035em;
  }
  h4 {
    font-size: 22px;
    letter-spacing: 0.1em;
  }
  span {
    color: $color-red;
  }
  li {
    list-style-type: disc;
    @include swap_direction(margin, 0 0 0 2.6em);
  }
}
